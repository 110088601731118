import AppLayout from "@features/app-layout";
import { DataPlatformPageWrapper } from "@features/data-platform";
import GasManagerPageWrapper from "@features/gas-policies/GasManagerPageWrapper";
import { RedirectToSatsuma } from "@features/satsuma/RedirectToSatsuma.tsx";
import useAnalyticsInDash from "@hooks/useAnalyticsInDash";
import { WaffleFlag, useWaffleFlag } from "@queries/useWaffleFlags";
import { Path, pathBuilder } from "@util/paths";
import { Redirect, Switch } from "react-router-dom";
import {
  LazyAccountsConfigCreatePage,
  LazyAccountsConfigEditPage,
  LazyAccountsPage,
  LazyAlertsHubPage,
  LazyAppsDetailsMultiNetworkPage,
  LazyAppsPage,
  LazyChatWeb3RoutesPage,
  LazyComposerPage,
  LazyConfigureAlertsPage,
  LazyCreateAppPage,
  LazyDataPlatformCreatePage,
  LazyDataPlatformListPage,
  LazyDataPlatformPipelinePage,
  LazyDowngradePage,
  LazyGasManagerPage,
  LazyGasPolicyCreatePage,
  LazyGasPolicyEditPage,
  LazyGasPolicyReviewPage,
  LazyHomePage,
  LazyLogoutPage,
  LazyMempoolPage,
  LazyPaymentSettingsPage,
  LazyProductLandingPage,
  LazyProductsPage,
  LazyQuerySearchPage,
  LazyRequestLogsPage,
  LazyRollsupPage,
  LazyRollupPage,
  LazySettingsPage,
  LazySupportPage,
  LazyTestPage,
  LazyTransactionPage,
  LazyUsagePage,
  LazyWebhooksPage,
} from "./LazyPages.tsx";
import { Route } from "./Route.tsx";

const App = () => {
  useAnalyticsInDash();
  const isSignerServiceEnabled = useWaffleFlag(WaffleFlag.SignerService);

  return (
    <AppLayout>
      <Switch>
        <Route path={Path.Home} component={LazyHomePage} exact />
        <Route path={Path.Apps} component={LazyAppsPage} exact />
        <Route path={Path.Products} component={LazyProductsPage} exact />
        <Route
          path={`${Path.Products}/:product`}
          component={LazyProductLandingPage}
          exact
        />
        <Route path={Path.CreateApp} component={LazyCreateAppPage} exact />
        <Route
          path={`${Path.Apps}/:appId(\\w+)/(setup|metrics|networks|settings)?`}
          component={LazyAppsDetailsMultiNetworkPage}
          exact
        />

        <Route path={Path.Rollups} component={LazyRollsupPage} exact />
        <Route
          path={`${Path.Rollups}/:raasId`}
          component={LazyRollupPage}
          exact
        />

        <Route path={Path.ChatWeb3} component={LazyChatWeb3RoutesPage} />
        <Route path={Path.GasManager} component={GasManagerComponent} />
        <Route path={Path.DataPlatform} component={DataPlatformComponent} />
        <Redirect path="/gasManager" to={Path.GasManager} />
        <Route
          path={Path.Accounts}
          component={isSignerServiceEnabled ? AccountsComponent : undefined}
        />
        <Route path={Path.Logout} component={LazyLogoutPage} exact />
        <Route path={Path.NewLogs} component={LazyRequestLogsPage} exact />
        <Route path={Path.Logs} component={LazyQuerySearchPage} exact />
        <Route
          path={`${Path.Logs}/:appId(\\w+)`}
          component={LazyQuerySearchPage}
          exact
        />
        <Redirect path="/(search|explorer)" to={Path.Logs} exact />
        <Route path={Path.Sandbox} component={LazyComposerPage} exact />
        <Redirect
          path="/(composer|api-explorer)"
          to={{ pathname: Path.Sandbox, search: location.search }}
          exact
        />
        <Route
          path={`${Path.Billing}(/upgrade)?`}
          component={LazyPaymentSettingsPage}
          exact
        />
        <Route
          path={`${Path.Billing}/downgrade`}
          component={LazyDowngradePage}
          exact
        />
        <Route
          path={`${Path.ConfigureAlerts}/:alertId?`}
          component={LazyConfigureAlertsPage}
          exact
        />
        <Route path={Path.AlertsHub} component={LazyAlertsHubPage} exact />
        <Route
          path={`${Path.Settings}/:tab`}
          component={LazySettingsPage}
          exact
        />
        <Route path={Path.Support} component={LazySupportPage} exact />
        <Route path={Path.Mempool} component={LazyMempoolPage} exact />
        <Route
          path={`${Path.Mempool}/:networkPrefix/tx/:txId`}
          component={LazyTransactionPage}
          exact
        />
        <Redirect path="/mempool" to={Path.Mempool} exact />
        <Route path={Path.Usage} component={LazyUsagePage} exact />
        <Route
          path={`${Path.Usage}/:appId(\\w+)`}
          component={LazyUsagePage}
          exact
        />
        <Route path={Path.Webhooks} component={LazyWebhooksPage} exact />
        <Redirect path="/notify" to={Path.Webhooks} exact />

        <Route
          path={Path.SubgraphsRedirect}
          component={RedirectToSatsuma}
          exact
        />

        <Route path="/test" component={LazyTestPage} exact />
        <Redirect to={Path.Home} />
      </Switch>
    </AppLayout>
  );
};

function GasManagerComponent() {
  return (
    <GasManagerPageWrapper>
      <Switch>
        <Route path={Path.GasManager} component={LazyGasManagerPage} exact />
        <Route
          path={Path.GasManagerPolicyCreate}
          component={LazyGasPolicyCreatePage}
          exact
        />
        <Route
          path={`${Path.GasManagerPolicy}/:policyId`}
          component={LazyGasPolicyReviewPage}
          exact
        />
        <Route
          path={`${Path.GasManagerPolicy}/:policyId/edit`}
          component={LazyGasPolicyEditPage}
          exact
        />
        <Redirect to={Path.GasManager} />
      </Switch>
    </GasManagerPageWrapper>
  );
}

function DataPlatformComponent() {
  return (
    <DataPlatformPageWrapper>
      <Switch>
        <Route
          path={Path.DataPlatform}
          component={LazyDataPlatformListPage}
          exact
        />
        <Route
          path={pathBuilder.dataPlatform.pipeline(":id")}
          component={LazyDataPlatformPipelinePage}
          exact
        />
        <Route
          path={Path.DataPlatformCreate}
          component={LazyDataPlatformCreatePage}
          exact
        />
        <Redirect to={Path.DataPlatform} />
      </Switch>
    </DataPlatformPageWrapper>
  );
}

function AccountsComponent() {
  return (
    <Switch>
      <Route path={Path.Accounts} component={LazyAccountsPage} exact />
      <Route
        path={Path.AccountsConfigCreate}
        component={LazyAccountsConfigCreatePage}
        exact
      />
      <Route
        path={`${Path.Accounts}/config/:configId/edit`}
        component={LazyAccountsConfigEditPage}
        exact
      />
      <Redirect to={Path.Accounts} />
    </Switch>
  );
}

export default App;
