import { Documentation } from "@alch/ui/icons/24";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import { Path } from "@util/paths";
import { AlchemyDocsUrl } from "@util/urls";
import ProductBanner from "./ProductBanner";
import webhooksLogo from "./assets/webhooks-logo.svg";

interface ProductLaunchAnnouncementProps {
  onHide: () => void;
}

const ProductLaunchAnnouncement = ({
  onHide,
}: ProductLaunchAnnouncementProps) => {
  return (
    <ProductBanner
      theme="skyBlue"
      title={<>Webhooks Now Support 10+ Chains!</>}
      description={
        <span className="block max-w-md">
          Fast, consistent push notifications. Massive RPC savings.
        </span>
      }
      primaryButton={{
        as: ViewAsPreservingLink,
        to: Path.Webhooks,
        children: "Create a webhook",
        onClick: onHide,
      }}
      secondaryButton={{
        as: "a",
        href: AlchemyDocsUrl.WebhooksApi,
        target: "_blank",
        children: "Docs",
        icon: <Documentation />,
      }}
      image={{
        url: webhooksLogo,
        width: 300,
        height: "50%",
        className: "ml-auto mr-12 my-auto",
      }}
      onHide={onHide}
    />
  );
};

export default ProductLaunchAnnouncement;
