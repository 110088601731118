import { ChevronUp } from "@alch/ui/icons/16";
import * as Collapsible from "@radix-ui/react-collapsible";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useCallback } from "react";

import { NavItemGroup } from "./use-nav-items";

interface NavButtonGroupProps {
  group: NavItemGroup;
  children: React.ReactNode;
  expanded: boolean;
  onToggle: (group: NavItemGroup) => void;
}

const NavButtonGroup = ({
  group,
  children,
  expanded,
  onToggle,
}: NavButtonGroupProps) => {
  const handleToggle = useCallback(() => {
    onToggle(group);
  }, [group, onToggle]);

  return (
    <Collapsible.Root
      asChild
      open={!group.collapsible || expanded}
      onOpenChange={handleToggle}
      disabled={!group.collapsible}
    >
      <li>
        <Collapsible.Trigger
          className={classNames(
            "group flex w-full items-center rounded text-grayscale-400 outline-none transition",
            "focus-visible:shadow-focus-dark",
            "mb-0.5 mt-3 px-2 py-0.5 text-paragraph-100-regular",
          )}
        >
          {group.title}

          {group.collapsible ? (
            <ChevronUp className="ml-auto text-grayscale-500 transition group-hover:rotate-180 group-aria-expanded:rotate-180 group-aria-expanded:group-hover:rotate-0" />
          ) : null}
        </Collapsible.Trigger>

        <Collapsible.Content asChild forceMount>
          <motion.ul
            transition={{ duration: 0.15 }}
            initial={false}
            className="-mx-1 overflow-hidden px-1"
            animate={
              !group.collapsible || expanded
                ? {
                    opacity: 1,
                    height: "auto",
                    visibility: "visible",
                  }
                : {
                    opacity: 0,
                    height: 0,
                    transitionEnd: { visibility: "hidden" },
                  }
            }
          >
            {children}
          </motion.ul>
        </Collapsible.Content>
      </li>
    </Collapsible.Root>
  );
};

export default NavButtonGroup;
