import { AnnouncementBanner } from "@alch/ui";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import { OneTimeEvent, useOneTimeEventStorage } from "@util/localStorage";
import { Path } from "@util/paths";
import { useRouteMatch } from "react-router-dom";

const MultiNetworkAnnouncementBanner = () => {
  const [dismissed, setDismissed] = useOneTimeEventStorage(
    OneTimeEvent.MultiNetworkGlobalAnnouncement,
  );

  const isOnAppDetailsPage = !!useRouteMatch(`${Path.Apps}/:appId`);

  if (dismissed || isOnAppDetailsPage) return null;

  return (
    <AnnouncementBanner
      onDismiss={setDismissed}
      className="bg-gradient-to-r from-[#7291FF] to-blue-600 text-white [&>button:hover:enabled]:!bg-blue-700"
    >
      <AnnouncementBanner.Link
        as={ViewAsPreservingLink}
        to={Path.Apps}
        className="no-underline hover:!text-white/70"
        onClick={setDismissed}
      >
        ⛓️ Apps have become multi-chain! Unlock the power of all networks with a
        single API key.
      </AnnouncementBanner.Link>
    </AnnouncementBanner>
  );
};

export default MultiNetworkAnnouncementBanner;
