import { DataPlatformAPI } from "@features/data-platform/api.ts";
import { ChainDataType } from "@features/data-platform/constants.ts";
import {
  Datasource,
  GetPipelineMetricsSuccessResult,
  ListWebhookEventsSuccessResult,
  Pipeline,
} from "@features/data-platform/types.ts";
import { Chain, ChainInfo, Network, NetworkInfo } from "@util/constants.ts";
import { useInterval, usePageVisibility } from "@util/hooks.ts";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

export enum PipelineModalAction {
  STOP,
}

export type DataPlatformModalContextData = {
  action: PipelineModalAction;
  pipelineID: number | string;
};

export type PipelineMetricsContext = Record<
  Pipeline["id"],
  GetPipelineMetricsSuccessResult
>;

export type PipelineEventsContext = Record<
  Pipeline["id"],
  ListWebhookEventsSuccessResult["events"]
>;

interface DataPlatformContextData {
  loading: boolean;
  loaded: boolean;

  modal: DataPlatformModalContextData | null;
  openModal: (action: PipelineModalAction, pipelineID: number | string) => void;
  closeModal: () => void;
  dataSources: Datasource[];

  pipelines: Pipeline[];
  refreshPipelines: () => Promise<void>;

  pipelineMetrics: PipelineMetricsContext;
  setPipelineMetrics: Dispatch<SetStateAction<PipelineMetricsContext>>;
  pipelineEvents: PipelineEventsContext;
  setPipelineEvents: Dispatch<SetStateAction<PipelineEventsContext>>;

  availableChains: Set<Chain> | undefined;
  availableNetworks: Set<Network> | undefined;
  availableDatatypesByNetwork:
    | Partial<Record<Network, Set<ChainDataType>>>
    | undefined;

  chainInfos: ChainInfo[];
  networkInfos: NetworkInfo[];
}

export const DataPlatformContext = createContext<DataPlatformContextData>({
  loading: true,
  loaded: false,
  modal: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal: () => {},
  dataSources: [],
  pipelines: [],

  pipelineMetrics: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPipelineMetrics: () => {},
  pipelineEvents: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPipelineEvents: () => {},

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshPipelines: async () => {},
  availableChains: undefined,
  availableNetworks: undefined,
  availableDatatypesByNetwork: undefined,

  chainInfos: [],
  networkInfos: [],
});

export const useDataPlatformContext = () => {
  return useContext(DataPlatformContext);
};

export const usePipelineMetrics = (
  pipelineID: Pipeline["id"],
): GetPipelineMetricsSuccessResult => {
  const { pipelineMetrics, setPipelineMetrics } = useDataPlatformContext();
  const { isVisible } = usePageVisibility();

  const loadMetrics = useCallback(async () => {
    const metrics = await DataPlatformAPI.getMetrics(pipelineID);

    setPipelineMetrics((prev) => ({
      ...prev,
      [pipelineID]: metrics,
    }));
  }, [pipelineID, setPipelineMetrics]);

  useEffect(() => {
    if (!pipelineMetrics[pipelineID]) {
      void loadMetrics();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run once when mounted
  }, []);

  // Fetch metrics every 10 seconds, if the page is still open.
  useInterval(() => {
    if (isVisible) {
      void loadMetrics();
    }
  }, 10000);

  return pipelineMetrics[pipelineID];
};

export const usePipelineEvents = (pipelineID: Pipeline["id"]) => {
  const { pipelineEvents, setPipelineEvents } = useDataPlatformContext();
  const { isVisible } = usePageVisibility();

  const loadEvents = useCallback(async () => {
    const { events } = await DataPlatformAPI.getEvents(pipelineID);

    setPipelineEvents((prev) => ({
      ...prev,
      [pipelineID]: events,
    }));
  }, [pipelineID, setPipelineEvents]);

  useEffect(() => {
    if (!pipelineEvents[pipelineID]) {
      void loadEvents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Run once when mounted
  }, []);

  // Fetch events every 10 seconds, if the page is still open.
  useInterval(() => {
    if (isVisible) {
      void loadEvents();
    }
  }, 10000);

  return pipelineEvents[pipelineID];
};

export const DataPlatformProvider = DataPlatformContext.Provider;
export const DataPlatformConsumer = DataPlatformContext.Consumer;
