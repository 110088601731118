import { IconButton, SearchField } from "@alch/ui";
import { ArrowLeft, Search01 } from "@alch/ui/icons/24";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { analyticsEvent, EventPrefix } from "@util/analytics";

import useSearchResults, { parseLinkFromValue } from "./useSearchResults";

interface SearchProps {
  withOverlays?: boolean;
  showOverlay?: boolean;
  setShowOverlay: (show: boolean) => void;
  autoFocus?: boolean;
}

const isRelativeLink = (to: string) => to.startsWith("/");

const Search = ({
  withOverlays,
  showOverlay,
  setShowOverlay,
  autoFocus,
}: SearchProps) => {
  const history = useHistory();

  const [query, setQuery] = useState("");
  const searchResults = useSearchResults(query.trim());

  const handleHideOverlay = useCallback(() => {
    setShowOverlay(false);
  }, [setShowOverlay]);

  const handleShowOverlay = useCallback(() => {
    setShowOverlay(true);
  }, [setShowOverlay]);

  const handleSelect = useCallback(
    (value: string) => {
      handleHideOverlay();

      const to = parseLinkFromValue(value);

      if (isRelativeLink(to)) {
        history.push(to);
      } else {
        window.open(to);
      }

      const selectedResult = searchResults
        .flatMap((group) => group.items)
        .find((result) => result.value === value);

      if (selectedResult) {
        analyticsEvent(`${EventPrefix.SearchBar}: Selected search result`, {
          result: selectedResult.label,
        });
      }
    },
    [history, handleHideOverlay, searchResults],
  );

  const debouncedSearchAnalyticsEvent = useMemo(
    () =>
      debounce((value: string) => {
        analyticsEvent(`${EventPrefix.SearchBar}: Typed in search bar`, {
          value,
        });
      }, 600),
    [],
  );

  const handleValueChange = useCallback(
    (value: string) => {
      setQuery(value);

      if (value.trim()) {
        debouncedSearchAnalyticsEvent(value);
      }
    },
    [debouncedSearchAnalyticsEvent],
  );

  const searchField = (
    <SearchField
      className="w-full"
      inputContainerClassName={
        autoFocus
          ? ""
          : "bg-blue-50 focus-within:bg-transparent hover:bg-transparent"
      }
      results={searchResults}
      placeholder="Search"
      size="sm"
      value={query}
      onValueChange={handleValueChange}
      onResultSelect={handleSelect}
      autoFocus={autoFocus}
      hotkey={autoFocus ? "mod+k" : "/"}
    />
  );

  return (
    <>
      {withOverlays ? (
        <IconButton
          className="self-center text-grayscale-400"
          padding="lg"
          onClick={handleShowOverlay}
        >
          <Search01 />
        </IconButton>
      ) : null}

      {withOverlays ? (
        <AnimatePresence initial={false}>
          {showOverlay ? (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 0.15 }}
              className="fixed inset-0 z-10 flex items-start gap-4 bg-white p-4"
            >
              <IconButton
                className="mt-1 text-grayscale-700 md:hidden"
                padding="lg"
                onClick={handleHideOverlay}
              >
                <ArrowLeft />
              </IconButton>

              {searchField}
            </motion.div>
          ) : null}
        </AnimatePresence>
      ) : (
        <div className={clsx("w-full", autoFocus ? "" : "max-w-[360px]")}>
          {searchField}
        </div>
      )}
    </>
  );
};

export default Search;
