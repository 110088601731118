import { Path } from "@util/paths.ts";

interface SiteLink {
  label: string;
  to: string;
  terms: string[];
  description?: string;
}

const SITE_LINKS: SiteLink[] = [
  {
    label: "Home",
    to: Path.Home,
    terms: ["dashboard", "home"],
  },
  {
    label: "Apps",
    to: Path.Apps,
    terms: ["apps", "key"],
    description: "List & manage your apps.",
  },
  {
    label: "Create App",
    to: Path.Apps,
    terms: ["create", "apps"],
    description: "Create a new app.",
  },
  {
    label: "Logs",
    to: Path.Logs,
    terms: ["explorer", "requests", "logs"],
    description: "View your API requests.",
  },
  {
    label: "Sandbox",
    to: Path.Sandbox,
    terms: ["composer", "requests", "sandbox"],
    description: "Make requests against the blockchain from the browser.",
  },
  {
    label: "Transactions",
    to: Path.Mempool,
    terms: ["mempool", "transactions"],
    description: "View mempool transactions generated from your requests.",
  },
  {
    label: "Webhooks",
    to: Path.Webhooks,
    terms: ["notify", "notifications", "webhooks"],
    description: "Create & manage webhooks",
  },
  {
    label: "Gas Manager",
    to: Path.GasManager,
    terms: ["pay", "account", "abstraction", "gas", "policies"],
  },
  {
    label: "Accounts Config",
    to: Path.Accounts,
    terms: ["accounts", "config", "signer", "magiclink", "passkey"],
  },
  {
    label: "Profile",
    to: Path.Profile,
    terms: ["profile", "password", "email", "name", "settings"],
    description: "Update your name, email and password.",
  },
  {
    label: "Team",
    to: Path.Team,
    terms: ["team", "users", "invite"],
    description: "Invite and manage team members.",
  },
  {
    label: "Billing",
    to: Path.Billing,
    terms: ["billing", "subscription", "plan"],
    description: "Update your plan and billing information.",
  },
  {
    label: "Usage",
    to: Path.Usage,
    terms: ["usage", "units", "capacity", "balance"],
    description: "Track current & historical compute units usage.",
  },
];

export default SITE_LINKS;
