import { Product } from "@alch/dx-webapp-api/shared";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { AlchemyDocsUrl, Url } from "@util/urls";
import { RootState } from "../../../redux/root";

import { useWaffleFlag, WaffleFlag } from "@queries/useWaffleFlags";
import { Path, pathBuilder } from "@util/paths.ts";

interface BaseNavItem {
  label: string;
  highlight?: boolean;
  onClick?: () => void;
}

export interface InternalNavLink extends BaseNavItem {
  to: string;
  exact?: boolean;
}

export interface ExternalNavLink extends BaseNavItem {
  href?: string;
}

export type NavLink = InternalNavLink | ExternalNavLink;

export interface NavItemGroup {
  title: string;
  collapsible?: boolean;
  items: NavLink[];
}

export type NavItem = NavLink | NavItemGroup;

export const isExternalLink = (item: NavItem): item is ExternalNavLink => {
  return (item as ExternalNavLink).href != null;
};

export const isInternalLink = (item: NavItem): item is InternalNavLink => {
  return (item as InternalNavLink).to != null;
};

export const isGroupItem = (item: NavItem): item is NavItemGroup => {
  return (item as NavItemGroup).items != null;
};

const useNavItems = (): NavItem[] => {
  const user = useSelector((state: RootState) => state.users.currentUser);
  const isBillingAdmin = !!user.value?.is_billing_admin;

  const isSignerServiceEnabled = useWaffleFlag(WaffleFlag.SignerService);
  const allowDataPlatformFlag = useSelector(
    (state: RootState) => state.team.team?.value?.flags?.allow_data_platform,
  );

  return useMemo(() => {
    return [
      {
        label: "Overview",
        to: Path.Home,
        exact: true,
      },
      {
        label: "Apps",
        to: Path.Apps,
      },
      {
        label: "Products",
        to: Path.Products,
        exact: true,
      },
      {
        title: "Chain Services",
        items: [
          {
            label: "Rollups",
            to: Path.Rollups,
          },
        ],
      },
      {
        title: "APIs",
        items: [
          {
            label: "Node API",
            to: pathBuilder.products.landingPage(Product.NodeApi),
          },
          {
            label: "NFT API",
            to: pathBuilder.products.landingPage(Product.NftApi),
          },
          {
            label: "Token API",
            to: pathBuilder.products.landingPage(Product.TokenApi),
          },
          {
            label: "Transfers API",
            to: pathBuilder.products.landingPage(Product.TransfersApi),
          },
          {
            label: "Simulation API",
            to: pathBuilder.products.landingPage(
              Product.TransactionSimulationApi,
            ),
          },
          {
            label: "Smart Websockets",
            to: pathBuilder.products.landingPage(Product.Websockets),
          },
        ],
      },
      {
        title: "Data Services",
        items: [
          {
            label: "Webhooks",
            to: Path.Webhooks,
          },
          {
            label: "Subgraphs",
            href: Path.SubgraphsRedirect,
          },
          ...(allowDataPlatformFlag
            ? [
                {
                  label: "Pipelines",
                  to: Path.DataPlatform,
                },
              ]
            : []),
        ],
      },
      {
        title: "Wallet Services",
        items: [
          ...(isSignerServiceEnabled
            ? [
                {
                  label: "Embedded Accounts",
                  to: Path.Accounts,
                },
              ]
            : []),
          {
            label: "Gas Manager",
            to: Path.GasManager,
          },
        ],
      },
      {
        title: "Developer Tools",
        items: [
          {
            label: "Request Logs",
            to: Path.Logs,
          },
          {
            label: "Mempool",
            to: Path.Mempool,
          },
          {
            label: "Sandbox",
            to: Path.Sandbox,
          },
          {
            label: "SDKs",
            href: AlchemyDocsUrl.AlchemySDK,
          },
          {
            label: "Faucets",
            href: Url.Faucets,
          },
          {
            label: "Alchemy University",
            href: Url.University,
          },
        ],
      },
      {
        title: "Admin",
        collapsible: true,
        items: [
          {
            label: "Usage",
            to: Path.Usage,
          },
          ...(isBillingAdmin
            ? [
                {
                  label: "Billing",
                  to: Path.Billing,
                },
              ]
            : []),
          {
            label: "Alerts",
            to: Path.ConfigureAlerts,
          },
          {
            label: "Profile",
            to: Path.Profile,
          },
          {
            label: "Team",
            to: Path.Team,
          },
          ...(isBillingAdmin
            ? [
                {
                  label: "Access Keys",
                  to: Path.AccessKeys,
                },
              ]
            : []),
        ],
      },
    ];
  }, [isSignerServiceEnabled, isBillingAdmin, allowDataPlatformFlag]);
};

export default useNavItems;
