import { Action, combineReducers, Reducer } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { appRequestsReducer, AppRequestsState } from "./appRequests";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { appsReducer, AppsState } from "./apps";
import { appStatsReducer, AppStatsState } from "./appStats";
import { globalSettingsReducer, GlobalSettingsState } from "./globalSettings";
import { mempoolReducer, MempoolState } from "./mempool";
import { paymentsReducer, PaymentsState } from "./payments";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { teamReducer, TeamState } from "./team";
import { usageReducer, UsageState } from "./usage";
import { usersReducer, UsersState } from "./users";
import { waffleReducer, WaffleState } from "./waffle";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { webhooksReducer, WebhooksState } from "./webhooks";

export interface RootState {
  apps: AppsState;
  appRequests: AppRequestsState;
  appStats: AppStatsState;
  globalSettings: GlobalSettingsState;
  payments: PaymentsState;
  usage: UsageState;
  users: UsersState;
  team: TeamState;
  mempool: MempoolState;
  webhooks: WebhooksState;
  waffle: WaffleState;
}

// eslint-disable-next-line @typescript-eslint/ban-types -- FIXME
export type AppDispatch = ThunkDispatch<RootState, {}, Action>;
// eslint-disable-next-line @typescript-eslint/ban-types -- FIXME
export type AppThunkAction<R> = ThunkAction<R, RootState, {}, Action>;

export const rootReducer: Reducer<RootState> = combineReducers({
  apps: appsReducer,
  appRequests: appRequestsReducer,
  appStats: appStatsReducer,
  globalSettings: globalSettingsReducer,
  payments: paymentsReducer,
  usage: usageReducer,
  users: usersReducer,
  team: teamReducer,
  mempool: mempoolReducer,
  webhooks: webhooksReducer,
  waffle: waffleReducer,
});
