import { ProjectType } from "@alch/dx-webapp-api/shared";
import { Documentation } from "@alch/ui/icons/24";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import useSearchParam, { SearchParam } from "@hooks/useSearchParam";
import {
  EventPrefix,
  addRecommendationProperties,
  analyticsEvent,
} from "@util/analytics";
import { OneTimeEvent, useOneTimeEventStorage } from "@util/localStorage";
import { hasProperty } from "@util/objects";
import { trpc } from "@util/trpc/trpcClient";
import { useEffect, useState } from "react";
import ProductBanner from "./ProductBanner";
import ProductLaunchAnnouncement from "./ProductLaunchAnnouncement";
import bannerConfigs from "./banner-configs";

export const projectTypeLabels: Record<ProjectType, string> = {
  [ProjectType.Analytics]: "Analytics",
  [ProjectType.Defi]: "DeFi",
  [ProjectType.Gaming]: "Gaming",
  [ProjectType.Wallet]: "Wallet",
  [ProjectType.Security]: "Security",
  [ProjectType.Social]: "Social",
  [ProjectType.Marketplace]: "Marketplace",
  [ProjectType.InfraAndTooling]: "Infra & Tooling",
  [ProjectType.NFTs]: "NFTs",
  [ProjectType.Identity]: "Identity",
};

const RecommendedProductBanner = () => {
  const [hideLaunchAnnouncement, setHideLaunchAnnouncement] =
    useOneTimeEventStorage(OneTimeEvent.WebhooksSummerOfChains);
  const [hideRecommendedBanner, setHideRecommendedBanner] =
    useOneTimeEventStorage(OneTimeEvent.HomeRecommendedProduct);

  const [hasJustHiddenLaunchAnnouncement, setHasJustHiddenLaunchAnnouncement] =
    useState(false);

  const showLaunchAnnouncement = !hideLaunchAnnouncement;

  const signupOptions = trpc.signup.getSignupOptions.useQuery(undefined, {
    enabled:
      !hideRecommendedBanner &&
      !showLaunchAnnouncement &&
      !hasJustHiddenLaunchAnnouncement,
  });

  const projectTypeOverwrite = useSearchParam(
    SearchParam.TestSignupProjectType,
  );
  const recommendedProductOverwrite = useSearchParam(
    SearchParam.TestRecommendedProduct,
  );

  const projectType = signupOptions.isSuccess
    ? projectTypeOverwrite || signupOptions.data?.project_type || ""
    : "";

  const homeRecommendedProduct =
    trpc.products.getHomeRecommendedProduct.useQuery(
      { projectType },
      { enabled: !!projectType },
    );

  useEffect(() => {
    if (hideRecommendedBanner || !homeRecommendedProduct.data) return;

    addRecommendationProperties({
      homeRecommendedProduct: homeRecommendedProduct.data,
    });

    analyticsEvent(`${EventPrefix.HomeRecBanner}: Saw product banner`, {
      product: homeRecommendedProduct.data,
    });
  }, [hideRecommendedBanner, homeRecommendedProduct.data]);

  const recommendedProduct = homeRecommendedProduct.isSuccess
    ? recommendedProductOverwrite || homeRecommendedProduct.data
    : null;

  if (showLaunchAnnouncement) {
    return (
      <ProductLaunchAnnouncement
        onHide={() => {
          setHasJustHiddenLaunchAnnouncement(true);
          setHideLaunchAnnouncement();
        }}
      />
    );
  }

  if (hideRecommendedBanner || !recommendedProduct) {
    return null;
  }

  const bannerConfig = hasProperty(bannerConfigs, recommendedProduct)
    ? bannerConfigs[recommendedProduct]
    : null;

  if (!bannerConfig) return null;

  return (
    <ProductBanner
      theme={bannerConfig.theme}
      title={
        <>
          {hasProperty(projectTypeLabels, projectType)
            ? `${projectTypeLabels[projectType]} teams`
            : "Other teams like you"}{" "}
          use <em className="lg:whitespace-nowrap">{bannerConfig.name}</em>
        </>
      }
      description={bannerConfig.description}
      primaryButton={{
        as: ViewAsPreservingLink,
        to: bannerConfig.actionPath,
        children: bannerConfig.actionLabel,
        onClick: () => {
          analyticsEvent(`${EventPrefix.HomeRecBanner}: Clicked primary CTA`);
        },
      }}
      secondaryButton={{
        as: "a",
        href: bannerConfig.docsUrl,
        target: "_blank",
        rel: "noreferrer noopener",
        icon: <Documentation />,
        children: "Docs",
        onClick: () => {
          analyticsEvent(`${EventPrefix.HomeRecBanner}: Clicked Docs link`);
        },
      }}
      image={{ ...bannerConfig.image, className: "pt-4" }}
      onHide={() => {
        setHideRecommendedBanner();

        analyticsEvent(
          `${EventPrefix.HomeRecBanner}: Closed recommended product banner`,
        );
      }}
    />
  );
};

export default RecommendedProductBanner;
