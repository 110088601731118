import { Product } from "@alch/dx-webapp-api/shared";

import { Path } from "@util/paths";
import { AlchemyDocsUrl, Url } from "@util/urls";

import accountManagerUrl from "./assets/account-manager.png";
import gasManagerUrl from "./assets/gas-manager.png";
import nftApiUrl from "./assets/nft-api.png";
import subgraphsUrl from "./assets/subgraphs.png";
import tokenApiUrl from "./assets/token-api.png";
import txSimulationUrl from "./assets/tx-simulation.png";
import webhooksUrl from "./assets/webhooks.png";

import ProductBanner from "./ProductBanner";

interface ProductBannerConfig {
  theme: React.ComponentProps<typeof ProductBanner>["theme"];
  name: string;
  description: string;
  actionLabel: string;
  actionPath: string;
  docsUrl: string;
  image: React.ComponentProps<typeof ProductBanner>["image"];
}

const bannerConfigs: Partial<Record<Product, ProductBannerConfig>> = {
  [Product.Subgraphs]: {
    theme: "yellow",
    name: "Subgraphs",
    description:
      "Ship faster with a custom API for your onchain data. Reduce data lag by up to 50%. Backfill data up to 5x faster.",
    actionLabel: "Try subgraphs",
    actionPath: Path.SubgraphsRedirect,
    docsUrl: AlchemyDocsUrl.Subgraphs,
    image: { url: subgraphsUrl, width: 436, height: 280 },
  },
  [Product.Webhooks]: {
    theme: "blue",
    name: "Webhooks",
    description:
      "Fast, consistent push notifications. Zero missed updates. Massive savings.",
    actionLabel: "Try webhooks",
    actionPath: Path.Webhooks,
    docsUrl: AlchemyDocsUrl.WebhooksApi,
    image: { url: webhooksUrl, width: 436, height: 280 },
  },
  [Product.GasManagerApi]: {
    theme: "pink",
    name: "Gas Manager",
    description:
      "Abstract away gas from your users, and programmatically control gas policies using the Gas Manager Admin APIs.",
    actionLabel: "Try gas manager",
    actionPath: Path.GasManager,
    docsUrl: AlchemyDocsUrl.GasManager,
    image: { url: gasManagerUrl, width: 380, height: 218 },
  },
  [Product.EmbeddedAccounts]: {
    theme: "pink",
    name: "Account Manager",
    description:
      "Make wallets invisible. Simple, non-custodial accounts to onboard users and transact with web2 UX.",
    actionLabel: "Try account manager",
    actionPath: Path.Accounts,
    docsUrl: Url.EmbeddedAccounts,
    image: { url: accountManagerUrl, width: 380, height: 218 },
  },
  [Product.TransactionSimulationApi]: {
    theme: "purple",
    name: "Tx Simulation",
    description:
      "Transact with confidence. Preview how transactions will behave onchain, and keep your assets safe.",
    actionLabel: "Explore in sandbox",
    actionPath: `${Path.Sandbox}?network=ETH_MAINNET&method=alchemy_simulateExecution`,
    docsUrl: AlchemyDocsUrl.TransactionSimulationApi,
    image: { url: txSimulationUrl, width: 436, height: 280 },
  },
  [Product.TokenApi]: {
    theme: "yellow",
    name: "Token API",
    description:
      "The token data your users expect, no token list required. Available on all EVM-supported chains.",
    actionLabel: "Explore in sandbox",
    actionPath: `${Path.Sandbox}?network=ETH_MAINNET&method=alchemy_getTokenAllowance`,
    docsUrl: AlchemyDocsUrl.TokenApi,
    image: { url: tokenApiUrl, width: 436, height: 280 },
  },
  [Product.NftApi]: {
    theme: "blue",
    name: "NFT API",
    description:
      "The multi-chain API to launch, verify, analyze, trade and display NFTs. Faster, easier, free.",
    actionLabel: "Explore in sandbox",
    actionPath: `${Path.Sandbox}?network=ETH_MAINNET&method=getNFTsForOwner_v3`,
    docsUrl: AlchemyDocsUrl.NftApi,
    image: { url: nftApiUrl, width: 436, height: 254 },
  },
};

export default bannerConfigs;
