import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  InternalNavLink,
  NavItem,
  isGroupItem,
  isInternalLink,
} from "./use-nav-items";

const useNavActiveItem = (items: NavItem[]) => {
  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = useState<InternalNavLink>();

  const matchesPath = useCallback(
    (item: NavItem): item is InternalNavLink => {
      if (!isInternalLink(item)) {
        return false;
      }

      if (item.exact) {
        return item.to === pathname;
      }

      return pathname.startsWith(item.to);
    },
    [pathname],
  );

  useEffect(() => {
    for (const item of items) {
      const nestedItems = isGroupItem(item) ? item.items : [item];
      const matchingItem = nestedItems.find(matchesPath);

      if (matchingItem) {
        return setActiveItem(matchingItem);
      }
    }

    setActiveItem(undefined);
  }, [items, matchesPath]);

  return activeItem;
};

export default useNavActiveItem;
