import { useCallback, useMemo } from "react";

import useApps from "@queries/useApps";
import { pathBuilder } from "@util/paths.ts";
import useNavItems, { isGroupItem, isInternalLink } from "../nav/use-nav-items";

import SITE_LINKS from "./siteLinks";
import useSearchDocs from "./useSearchDocs";

interface SearchLink {
  label: string;
  description?: string;
  to: string;
  terms?: string[];
}

interface SearchResult {
  title: string;
  items: Array<SearchLink & { value: string }>;
}

export const formatLinkValue = (link: SearchLink) =>
  `${link.to}:::${link.label}`;

export const parseLinkFromValue = (value: string) =>
  String(value).split(":::")[0];

const useSearchResults = (query: string): SearchResult[] => {
  const apps = useApps();

  const docsLinks = useSearchDocs(query);

  const navItems = useNavItems();
  const toolsLinks = useMemo(
    () => [
      ...SITE_LINKS,
      ...navItems
        .flatMap((item) => (isGroupItem(item) ? item.items : item))
        .filter(isInternalLink)
        .filter((item) => SITE_LINKS.every((link) => link.to !== item.to))
        .map((item) => ({
          ...item,
          terms: [item.label.toLowerCase()],
          description: undefined,
        })),
    ],
    [navItems],
  );

  const appsLinks = useMemo(
    () =>
      apps.data?.map((app) => ({
        label: app.name,
        to: pathBuilder.appDetails(app.id),
      })) || [],
    [apps],
  );

  const lowerCasedQuery = query.toLowerCase();
  const matchesQuery = useCallback(
    (link: SearchLink) =>
      link.label.toLowerCase().includes(lowerCasedQuery) ||
      link.description?.toLowerCase().includes(lowerCasedQuery) ||
      link.terms?.some((term) => term.toLowerCase().includes(lowerCasedQuery)),
    [lowerCasedQuery],
  );

  return useMemo(
    () => [
      {
        title: "Tools",
        items: toolsLinks.filter(matchesQuery).map((link) => ({
          value: formatLinkValue(link),
          ...link,
        })),
      },
      {
        title: "Apps",
        items: appsLinks
          .filter(matchesQuery)
          .slice(0, query ? 3 : 0)
          .map((link) => ({
            value: formatLinkValue(link),
            ...link,
          })),
      },
      {
        title: "Docs",
        items: docsLinks.map((link) => ({
          value: formatLinkValue(link),
          ...link,
        })),
      },
    ],
    [toolsLinks, appsLinks, docsLinks, query, matchesQuery],
  );
};

export default useSearchResults;
