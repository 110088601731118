import LazyPage from "@features/common/LazyPage";
import { ComponentProps, FC, lazy, LazyExoticComponent } from "react";

/**
 * Wraps the given component in LazyPage, which uses a <Suspense>.
 * Note: Until we fix theme-ui types, this doesn't work for pages that take props
 */
function withLoadable<P extends object>(Component: LazyExoticComponent<FC>) {
  return function Loadable(props: P) {
    return (
      <LazyPage>
        <Component {...props} />
      </LazyPage>
    );
  };
}

export const LazyAccountsConfigCreatePage = withLoadable(
  lazy(() => import("@features/accounts/config/create/page.tsx")),
);

export const LazyAccountsConfigEditPage = withLoadable(
  lazy(() => import("@features/accounts/config/edit/page.tsx")),
);

export const LazyAccountsPage = withLoadable(
  lazy(() => import("@features/accounts/page.tsx")),
);

export const LazyAlertsHubPage = withLoadable(
  lazy(() => import("@features/notifications/AlertsHubPage.tsx")),
);

export const LazyAppsDetailsMultiNetworkPage = withLoadable(
  lazy(
    () =>
      import("@features/app-details-multi-network/AppDetailsMultiNetworkPage"),
  ),
);

export const LazyAppsPage = withLoadable(
  lazy(() => import("@features/apps/AppsPage")),
);

export const LazyCreateAppPage = withLoadable(
  lazy(() => import("@features/create-app/CreateAppPage")),
);

export const LazyConfigureAlertsPage = withLoadable(
  lazy(() => import("@features/alerts/ConfigureAlertsPage.tsx")),
);

export const LazyDowngradePage = withLoadable(
  lazy(() => import("@features/profile/DowngradePage/DowngradePage.tsx")),
);

export const LazyChatWeb3RoutesPage = withLoadable(
  lazy(() => import("@features/chat-web3/ChatWeb3Routes")),
);

export const LazyRollsupPage = withLoadable(
  lazy(() => import("@features/chain-services/rollups/RollupsPage")),
);

export const LazyRollupPage = withLoadable(
  lazy(() => import("@features/chain-services/rollup/RollupPage")),
);

const LazyComposerPageComponent = lazy(() => import("@features/composer"));
export const LazyComposerPage = () => (
  <LazyPage>
    <LazyComposerPageComponent />
  </LazyPage>
);

export const LazyGasManagerPage = withLoadable(
  lazy(() => import("@features/gas-policies/GasManagerPage")),
);

export const LazyGasPolicyReviewPage = withLoadable(
  lazy(() => import("@features/gas-policies/GasPolicyReviewPage")),
);

export const LazyGasPolicyCreatePage = withLoadable(
  lazy(() => import("@features/gas-policies/form/GasPolicyCreatePage")),
);

export const LazyGasPolicyEditPage = withLoadable(
  lazy(() => import("@features/gas-policies/form/GasPolicyEditPage")),
);

export const LazyHomePage = withLoadable(
  lazy(() => import("@features/home/HomePage")),
);

export const LazyLogoutPage = withLoadable(lazy(() => import("./LogoutPage")));

export const LazyMempoolPage = withLoadable(
  lazy(() => import("@features/mempool/MempoolPage")),
);

export const LazyPaymentSettingsPage = withLoadable(
  lazy(() => import("@features/profile/PaymentSettings")),
);

export const LazyRequestLogsPage = withLoadable(
  lazy(() => import("@features/request-logs/RequestLogsPage.tsx")),
);

const LazySettingsPageComponent = lazy(
  () => import("@features/profile/SettingsPage"),
);
export const LazySettingsPage = (
  props: ComponentProps<typeof LazySettingsPageComponent>,
) => (
  <LazyPage>
    <LazySettingsPageComponent {...props} />
  </LazyPage>
);

export const LazySignupRoutes = withLoadable(
  lazy(() => import("@features/signup/SignupRoutes.tsx")),
);

export const LazySupportPage = withLoadable(
  lazy(() => import("@features/support/SupportPage")),
);

export const LazyQuerySearchPage = withLoadable(
  lazy(() => import("@features/query-search/QuerySearchPage")),
);

export const LazyTestPage = withLoadable(
  lazy(() => import("@features/debug/TestPage")),
);

export const LazyTransactionPage = withLoadable(
  lazy(() => import("@features/mempool/TransactionPage")),
);

export const LazyUsagePage = withLoadable(
  lazy(() => import("@features/usage/UsagePage")),
);

export const LazyWebhooksPage = withLoadable(
  lazy(() => import("@features/webhooks/RootPage")),
);
export const LazyDataPlatformListPage = withLoadable(
  lazy(() => import("@features/data-platform/ListView/index")),
);

export const LazyDataPlatformPipelinePage = withLoadable(
  lazy(() => import("@features/data-platform/PipelineView/index")),
);

export const LazyDataPlatformCreatePage = withLoadable(
  lazy(() => import("@features/data-platform/CreateView")),
);

export const LazyProductsPage = withLoadable(
  lazy(() => import("@features/products/ProductsPage")),
);

export const LazyProductLandingPage = withLoadable(
  lazy(() => import("@features/product-landing/ProductLandingPage")),
);
