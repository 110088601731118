import { combineReducers, Reducer } from "redux";
import { chainFrom } from "transducist";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
import { FreshneshParams } from "../http/endpoints";
import { dateFormatter } from "../util/date";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForIndexedLoadables,
  reducerForLoadable,
} from "../util/loadable";
import { AppIdParams } from "./apps";

export interface AppStatsState {
  stats: Loadable<{ [appId: string]: AppStats }>;
  queryCounts24h: Loadable<{ [appId: string]: number }>;
  medianResponses5m: Loadable<{ [appId: string]: number }>;
  singleMedianResponses5m: { [appId: string]: Loadable<number | null> };
  teamQueryCount: Loadable<number>;
  queryTimeSeries: Loadable<{ [appId: string]: TimeSeriesPoint[] }>;
  rateLimitTimeSeries: Loadable<{ [appId: string]: TimeSeriesPoint[] }>;
  detailedStats: { [appId: string]: Loadable<AppDetailedStats> };
}

export interface AppStats {
  rate_limit_24h: number;
  invalid_requests_24h: number;
}

export interface AppDetailedStats {
  qps_5m: number;
  fcups_5m: number;
  concurrent_requests_5m: number;
  success_rate_1h?: number;
  success_rate_24h?: number;
}

export interface TimeSeriesPoint {
  timestamp: string;
  value: number;
}

const requestAppStats = makeLoadingActionCreators<
  void,
  { [appId: string]: AppStats }
>("REQUEST_APP_STATS");

const requestAllQueryCounts = makeLoadingActionCreators<
  void,
  { [appId: string]: number }
>("REQUEST_ALL_APP_QUERY_COUNTS");

const requestSingleAppMedianResponse = makeLoadingActionCreators<
  string,
  number | null
>("REQUEST_SINGLE_APP_MEDIAN_RESPONSES");

const requestAppMedianResponses = makeLoadingActionCreators<
  void,
  { [appId: string]: number }
>("REQUEST_APP_MEDIAN_RESPONSES");

const requestTeamQueryCount = makeLoadingActionCreators<
  FreshneshParams,
  number
>("REQUEST_TEAM_QUERY_COUNT");

const requestTeamQueryTimeSeries = makeLoadingActionCreators<
  void,
  { [appId: string]: TimeSeriesPoint[] }
>("REQUEST_APP_QUERY_TIME_SERIES");

const requestTeamRateLimitTimeSeries = makeLoadingActionCreators<
  void,
  { [appId: string]: TimeSeriesPoint[] }
>("REQUEST_APP_RATE_LIMIT_TIME_SERIES");

const requestAppDetailedStats = makeLoadingActionCreators<
  AppIdParams,
  AppDetailedStats
>("REQUEST_APP_DETAILED_STATS");

export const fetchAppStats = makeFetchThunkActionCreator<
  void,
  { [appId: string]: AppStats }
>({
  actionCreators: requestAppStats,
  getFromState: (state) => state.appStats.stats,
  fetchResult: () =>
    http.getAppStats().then(({ stats_by_app_id }) => stats_by_app_id),
});

export const fetchAllAppQueryCounts = makeFetchThunkActionCreator<
  void,
  { [appId: string]: number }
>({
  actionCreators: requestAllQueryCounts,
  getFromState: (state) => state.appStats.queryCounts24h,
  fetchResult: () =>
    http
      .getAllAppQueryCounts()
      .then(({ query_counts_by_app_id }) => query_counts_by_app_id),
});

export const fetchSingleAppMedianResponse = makeFetchThunkActionCreator<
  string,
  number | null
>({
  actionCreators: requestSingleAppMedianResponse,
  getFromState: (state, appId) =>
    state.appStats.singleMedianResponses5m[appId] || {},
  fetchResult: (appId) => {
    return http
      .getAppMedianResponses({ app_id: appId })
      .then((results) => results?.[0]?.median_response_5m);
  },
});

export const fetchAppMedianResponses = makeFetchThunkActionCreator<
  void,
  { [appId: string]: number }
>({
  actionCreators: requestAppMedianResponses,
  getFromState: (state) => state.appStats.medianResponses5m,
  fetchResult: () =>
    http.getAppMedianResponses().then((results) =>
      chainFrom(results).toObject(
        (r) => r.id,
        (r) => r.median_response_5m,
      ),
    ),
});

export const fetchTeamQueryCount = makeFetchThunkActionCreator<
  FreshneshParams,
  number
>({
  actionCreators: requestTeamQueryCount,
  getFromState: (state) => state.appStats.teamQueryCount,
  fetchResult: (params: FreshneshParams) =>
    http.getTeamQueryCount(params).then(({ num_queries }) => num_queries),
});

export const fetchTeamQueryTimeSeries = makeFetchThunkActionCreator<
  void,
  { [appId: string]: TimeSeriesPoint[] }
>({
  actionCreators: requestTeamQueryTimeSeries,
  getFromState: (state) => state.appStats.queryTimeSeries,
  fetchResult: () =>
    http.getTeamQueryTimeSeries({
      start_date: +dateFormatter.utc.lastWeek(),
      end_date: +dateFormatter.utc.now(),
      granularity: "day",
    }),
});

export const fetchTeamRateLimitTimeSeries = makeFetchThunkActionCreator<
  void,
  { [appId: string]: TimeSeriesPoint[] }
>({
  actionCreators: requestTeamRateLimitTimeSeries,
  getFromState: (state) => state.appStats.rateLimitTimeSeries,
  fetchResult: () =>
    http.getTeamRateLimitTimeSeries({
      start_date: +dateFormatter.utc.lastHour(),
      end_date: +dateFormatter.utc.now(),
      granularity: "minute",
    }),
});

export const fetchAppDetailedStats = makeFetchThunkActionCreator<
  AppIdParams,
  AppDetailedStats
>({
  actionCreators: requestAppDetailedStats,
  getFromState: (state, params) =>
    state.appStats.detailedStats[params.app_id] || Loadable.unloaded(),
  fetchResult: http.getAppDetailedStats,
});

export const appStatsReducer: Reducer<AppStatsState> = combineReducers({
  stats: reducerForLoadable(requestAppStats),
  queryCounts24h: reducerForLoadable(requestAllQueryCounts),
  medianResponses5m: reducerForLoadable(requestAppMedianResponses),
  singleMedianResponses5m: reducerForIndexedLoadables(
    requestSingleAppMedianResponse,
    (appId) => appId,
  ),
  teamQueryCount: reducerForLoadable(requestTeamQueryCount),
  queryTimeSeries: reducerForLoadable(requestTeamQueryTimeSeries),
  rateLimitTimeSeries: reducerForLoadable(requestTeamRateLimitTimeSeries),
  detailedStats: reducerForIndexedLoadables(
    requestAppDetailedStats,
    (params) => params.app_id,
  ),
});
