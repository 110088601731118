import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getTeamApps } from "../../http/endpoints";
import { App } from "../../redux/apps";

export const APPS_QUERY_KEY = ["apps"];

function useApps<T = App[]>(opts?: Partial<UseQueryOptions<App[], Error, T>>) {
  return useQuery({
    ...opts,
    queryKey: APPS_QUERY_KEY,
    queryFn: () => getTeamApps(),
  });
}

export default useApps;
