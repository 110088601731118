import { Loading } from "@alch/ui";
import { StopPipelineModal } from "@features/data-platform/ListView/StopModal.tsx";
import { DataPlatformAPI } from "@features/data-platform/api.ts";
import { ChainDataType } from "@features/data-platform/constants.ts";
import {
  DataPlatformModalContextData,
  DataPlatformProvider,
  PipelineEventsContext,
  PipelineMetricsContext,
  PipelineModalAction,
} from "@features/data-platform/context.tsx";
import { Datasource, Pipeline } from "@features/data-platform/types.ts";
import { useChainInfos } from "@queries/useChain";
import { useNetworkInfos } from "@queries/useNetwork";
import useTeam from "@queries/useTeam.ts";
import { Network } from "@util/constants.ts";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export const DataPlatformPageWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);

  const [dataSources, setDataSources] = useState<Datasource[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [modal, setModal] = useState<DataPlatformModalContextData | null>(null);
  const [pipelineMetrics, setPipelineMetrics] =
    useState<PipelineMetricsContext>({});
  const [pipelineEvents, setPipelineEvents] = useState<PipelineEventsContext>(
    {},
  );
  const team = useTeam();
  const networkInfos = useNetworkInfos();
  const chainInfos = useChainInfos();

  const openModal = useCallback(
    (action: PipelineModalAction, pipelineID: number | string) => {
      setModal({
        action,
        pipelineID,
      });
    },
    [setModal],
  );

  const closeModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const refreshPipelines = useCallback(async () => {
    if (team.data?.id) {
      const data = await DataPlatformAPI.listPipelines(team.data.id);
      setPipelines(data.pipelines);
    }
  }, [team.data, setPipelines]);

  const refreshDataSources = useCallback(async () => {
    const data = await DataPlatformAPI.listDatasources();
    setDataSources(data.datasources);
  }, [setDataSources]);

  const initialize = useCallback(async () => {
    setLoading(true);
    await Promise.all([refreshDataSources(), refreshPipelines()]);
    setLoaded(true);
    setLoading(false);
  }, [refreshDataSources, refreshPipelines]);

  useEffect(() => {
    if (!loaded && !loading && team.data?.id) {
      void initialize();
    }
  }, [initialize, loaded, loading, team.data?.id]);

  const availableChains = useMemo(() => {
    if (dataSources) {
      return new Set(dataSources.map((ds) => ds.chain));
    }
  }, [dataSources]);

  const availableNetworks: Set<Network> | undefined = useMemo(() => {
    if (dataSources) {
      return new Set(dataSources.map((ds) => Network[ds.network]));
    }
  }, [dataSources]);

  const availableDatatypesByNetwork: Partial<
    Record<Network, Set<ChainDataType>>
  > = useMemo(() => {
    if (availableNetworks) {
      const result: Partial<Record<Network, Set<ChainDataType>>> = {};
      for (const ds of dataSources) {
        const resolvedNetwork = Network[ds.network];
        const currentValue = result[resolvedNetwork];
        if (currentValue) {
          currentValue.add(ds.dataType);
        } else {
          result[resolvedNetwork] = new Set([ds.dataType]);
        }
      }
      return result;
    }

    return {};
  }, [availableNetworks, dataSources]);

  if (loading || networkInfos.isPending || chainInfos.isPending) {
    return <Loading className="mx-auto my-20" />;
  }

  const ctx = {
    pipelines,
    refreshPipelines,
    dataSources,
    loading,
    loaded,
    availableChains,
    availableNetworks,
    availableDatatypesByNetwork,
    modal,
    openModal,
    closeModal,
    pipelineMetrics,
    setPipelineMetrics,
    pipelineEvents,
    setPipelineEvents,
    chainInfos: chainInfos.data || [],
    networkInfos: networkInfos.data || [],
  };

  return (
    <DataPlatformProvider value={ctx}>
      {children}
      <StopPipelineModal />
    </DataPlatformProvider>
  );
};
