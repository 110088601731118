import { Route } from "@components/app/Route.tsx";
import ChainPage from "@features/signup/Pages/ChainPage";
import EnterpriseDetailsPage from "@features/signup/Pages/EnterpriseDetailsPage";
import PaymentPage from "@features/signup/Pages/PaymentPage";
import PlanPage from "@features/signup/Pages/PlanPage";
import ProjectPage from "@features/signup/Pages/ProjectPage";
import ReferrerPage from "@features/signup/Pages/ReferrerPage";
import TeamPage from "@features/signup/Pages/TeamPage";
import SignupFlowProvider from "@features/signup/SignupFlowProvider";
import {
  SignupStep,
  SignupStepPathMap,
  SignupStepsList,
} from "@features/signup/constants";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import useAnalyticsInSignup from "../../hooks/useAnalyticsInSignup";

const SignupStepComponentMap = {
  [SignupStep.Team]: TeamPage,
  [SignupStep.Project]: ProjectPage,
  [SignupStep.Chain]: ChainPage,
  [SignupStep.Plan]: PlanPage,
  [SignupStep.EnterpriseDetails]: EnterpriseDetailsPage,
  [SignupStep.Payment]: PaymentPage,
  [SignupStep.Referrer]: ReferrerPage,
};

const SignupRoutes = () => {
  useAnalyticsInSignup();
  const history = useHistory();

  const defaultLocation = {
    ...history.location,
    pathname: SignupStepPathMap[SignupStep.Team],
  };

  useEffectOnce(() => {
    // Send user back to start of signup flow if they refresh during signup
    history.replace(defaultLocation);
  });

  return (
    <SignupFlowProvider>
      <Switch>
        {SignupStepsList.map((step) => (
          <Route
            key={step}
            path={SignupStepPathMap[step]}
            component={SignupStepComponentMap[step]}
            exact
          />
        ))}
        <Redirect to={defaultLocation} />
      </Switch>
    </SignupFlowProvider>
  );
};

export default SignupRoutes;
