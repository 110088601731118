import classNames from "classnames";
import { HTMLMotionProps, motion } from "framer-motion";
import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";

import { analyticsEvent, EventPrefix } from "@util/analytics";
import NavButton from "./NavButton";
import NavButtonGroup from "./NavButtonGroup";
import NavFooter from "./NavFooter";
import NavHeader from "./NavHeader";
import NavScrollArea from "./NavScrollArea";
import NavSearch from "./NavSearch";
import {
  InternalNavLink,
  isGroupItem,
  NavItem,
  NavItemGroup,
  NavLink,
} from "./use-nav-items";

interface NavPanelProps extends HTMLMotionProps<"div"> {
  width: number | string;
  items: NavItem[];
  onHideOverlay?: () => void;
  activeItem: InternalNavLink | undefined;
  transitionDuration: number;
}

const NavPanel = ({
  className,
  width,
  items,
  onHideOverlay,
  activeItem,
  transitionDuration,
  ...props
}: NavPanelProps) => {
  const asOverlay = !!onHideOverlay;
  const [collapsedGroups, setCollapsedGroups] = useLocalStorage<string[]>(
    "NAV_COLLAPSED_GROUPS",
    [],
  );

  const handleItemClick = useCallback(
    (item: NavLink) => {
      if (!isGroupItem(item)) {
        analyticsEvent(`${EventPrefix.Navbar}: Clicked ${item.label}`);
      }

      item.onClick?.();
      onHideOverlay?.();
    },
    [onHideOverlay],
  );

  const handleToggleGroup = useCallback(
    (item: NavItemGroup) => {
      setCollapsedGroups((groups) =>
        groups.includes(item.title)
          ? groups.filter((group) => group !== item.title)
          : [...groups, item.title],
      );
    },
    [setCollapsedGroups],
  );

  return (
    <motion.div
      {...props}
      className={classNames(
        "z-10 flex flex-col transition-[border-radius] duration-200 md:rounded-2xl bg-grayscale-950",
        className,
      )}
      initial={asOverlay ? { opacity: 0, y: 100 } : false}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      style={{ width }}
      transition={{ duration: asOverlay ? 0.15 : transitionDuration }}
    >
      <NavHeader
        onLogoClick={onHideOverlay}
        onHideOverlay={asOverlay ? onHideOverlay : undefined}
      />

      <NavSearch onHideOverlay={asOverlay ? onHideOverlay : undefined} />

      <NavScrollArea
        className="min-h-0 flex-1"
        shadowClassName="from-grayscale-950"
      >
        <ul className="flex flex-col">
          {items.map((item) =>
            isGroupItem(item) ? (
              <NavButtonGroup
                key={item.title}
                expanded={!collapsedGroups.includes(item.title)}
                onToggle={handleToggleGroup}
                group={item}
              >
                {item.items.map((item) => (
                  <NavButton
                    key={item.label}
                    item={item}
                    active={item === activeItem}
                    onClick={handleItemClick}
                  />
                ))}
              </NavButtonGroup>
            ) : (
              <NavButton
                key={item.label}
                item={item}
                active={item === activeItem}
                onClick={handleItemClick}
              />
            ),
          )}
        </ul>
      </NavScrollArea>

      <NavFooter onHideOverlay={asOverlay ? onHideOverlay : undefined} />
    </motion.div>
  );
};

export default NavPanel;
