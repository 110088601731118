import { DateTime } from "luxon";

/**
 * Debouncing at anything less than the level of granularity requested from druid
 * doesn't affect the accuracy of results. Instead, it can help prevent refetching
 * when results won't change on further queries, e.g. with react-query.
 */
type DebounceGranularity = "minute" | "hour" | "day";

export const dateFormatter = {
  utc: {
    now: (granularity?: DebounceGranularity): Date => {
      return granularity
        ? DateTime.utc().startOf(granularity).toJSDate()
        : DateTime.utc().toJSDate();
    },
    lastHour: (granularity: DebounceGranularity = "minute"): Date => {
      return DateTime.utc().minus({ hour: 1 }).startOf(granularity).toJSDate();
    },
    today: (granularity: DebounceGranularity = "minute"): Date => {
      return DateTime.utc().startOf(granularity).toJSDate();
    },
    yesterday: (granularity: DebounceGranularity = "minute"): Date => {
      return DateTime.utc().minus({ days: 1 }).startOf(granularity).toJSDate();
    },
    lastWeek: (granularity: DebounceGranularity = "day"): Date => {
      return DateTime.utc().minus({ days: 6 }).startOf(granularity).toJSDate();
    },
    twoWeeksAgo: (granularity: DebounceGranularity = "day"): Date => {
      return DateTime.utc().minus({ days: 13 }).startOf(granularity).toJSDate();
    },
    lastMonth: (granularity: DebounceGranularity = "day"): Date => {
      return DateTime.utc().minus({ days: 30 }).startOf(granularity).toJSDate();
    },
    toLocal: (
      /**
       * @param ts - ISO 8601 timestamp in UTC
       */
      ts: string,
      /**
       * @param overrideTimezone - if true, will change the timezone and keep the
       * local time fixed instead of altering the timestamp (i.e. this will alter
       * the underlying timestamp)
       */
      opts?: { overrideTimezone?: boolean },
    ): Date => {
      const d = DateTime.fromISO(ts, { zone: "utc" });

      return d
        .setZone("default", { keepLocalTime: opts?.overrideTimezone })
        .toJSDate();
    },
  },
  /**
   * @param start - start date
   * @param end - end date
   * @param format - format string, e.g. "M/D"
   * @returns formatted time period string, e.g. "7/1 - 7/31"
   */
  formattedTimePeriod: (
    start: Date | undefined,
    end: Date | undefined,
    format: string,
  ): string => {
    const _start = start ? DateTime.fromJSDate(start) : undefined;
    const _end = end ? DateTime.fromJSDate(end) : undefined;

    if (_start && _end) {
      return `${_start.toFormat(format)} - ${_end.toFormat(format)}`;
    }

    if (_start) {
      return _start.toFormat(format);
    }

    if (_end) {
      return _end.toFormat(format);
    }

    return "";
  },
};
